<template>  
  <section class="hero is-light">
    <div class="hero-head">
      <Navbar />
    </div>

    <div class="hero-body">
      <div class="container">
        <h1 class="subtitle is-size-1">
          solutions for every use case
        </h1>
        <router-link :to="{ name: 'SignUp'}" class="button is-black">
          Join now
        </router-link>
      </div>
    </div>
  </section>

  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div data-aos="fade-down" class="column
          is-12-mobile
          is-4-tablet">
            <h1 class="subtitle is-size-1">
              Marketing
            </h1>
          </div>
          <div data-aos="fade-left" class="column mb-5
          is-12-mobile
          is-4-tablet">
            <div class="columns is-multiline is-mobile">
              <div class="column is-5-mobile is-12-tablet py-0">
                <figure class="image mb-5">
                  <img src="@/assets/clientgifts.jpg">
                </figure>
              </div>
              <div class="column is-7-mobile is-12-tablet py-0">
                <h4 class="subtitle is-size-3 mb-0">
                  Client gifts
                </h4>
                <p>Show clients you value their loyalty and partnership by providing exclusive merchandise to make them feel appreciated. A unique way to show your appreciation.</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" class="column
          is-12-mobile
          is-4-tablet">
            <div class="columns is-multiline is-mobile">
              <div class="column is-5-mobile is-12-tablet py-0">
                <figure class="image mb-5">
                  <img src="@/assets/conferences.jpg">
                </figure>
              </div>
              <div class="column is-7-mobile is-12-tablet py-0">
                <h4 class="subtitle is-size-3 mb-0">
                  Conferences
                </h4>
                <p>Remove the burden of lugging large heavy boxes of merchandise to give away at conferences. Instead, display a QR code or link that lets visitors pick what they want.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">

        <div class="level is-justify-content-center mb-6">
          <h1 class="subtitle is-size-1">
            For your people
          </h1>
        </div>

        <div class="columns">
          <div data-aos="fade-left" class="column mb-5
          is-12-mobile
          is-4-tablet">
            <div class="columns is-multiline is-mobile">
              <div class="column is-5-mobile is-12-tablet py-0">
                <figure class="image mb-5">
                  <img src="@/assets/teammerch.jpg">
                </figure>
              </div>
              <div class="column is-7-mobile is-12-tablet py-0">
                <h4 class="subtitle is-size-3 mb-0">
                  Team merch
                </h4>
                <p>Prepare for team events by delivering sweaters that fit perfectly, directly to your employees home. Or, get everyone on the same footing with customized shoes!</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" class="column mb-5
          is-12-mobile
          is-4-tablet">
            <div class="columns is-multiline is-mobile">
              <div class="column is-5-mobile is-12-tablet py-0">
                <figure class="image mb-5">
                  <img src="@/assets/newhirekits.jpg">
                </figure>
              </div>
              <div class="column is-7-mobile is-12-tablet py-0">
                <h4 class="subtitle is-size-3 mb-0">
                  New hire kits
                </h4>
                <p>We make it easy to provide new employees with custom swag kits, even if they work remotely.</p>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" class="column
          is-12-mobile
          is-4-tablet">
            <div class="columns is-multiline is-mobile">
              <div class="column is-5-mobile is-12-tablet py-0">
                <figure class="image mb-5">
                  <img src="@/assets/corporateoffsites.jpg">
                </figure>
              </div>
              <div class="column is-7-mobile is-12-tablet py-0">
                <h4 class="subtitle is-size-3 mb-0">
                  Corporate off-sites
                </h4>
                <p>Employees will never miss out on showing their team spirit at corporate off site events! Build a custom kit of goodies for them to enjoy without the headache of surveying for sizes - so you can have a great off site too!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Footer />
</template>

<script>
import { onBeforeMount, ref } from 'vue'
import Navbar from '@/components/Navbar1.vue'
import Footer from '@/components/Footer.vue'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default {
  components: { Navbar, Footer },
  setup() {
    onBeforeMount(() => {
      import('aos').then(AOS => AOS.init())
    })

    return {

    }
  }
}
</script>